import type { RumInitConfiguration } from "@datadog/browser-rum";

import { datadogDefaultConfig } from "@/plugins/datadog";

import { version } from "../../package.json";
import type { ServeConfig } from "./utils";

export const DISPLAY_CALENDAR = true;
export const IS_DEV = false;
export const IS_DEMO = false;
export const IS_STAGING = true;
export const IS_PROD = false;
export const IS_TEST = false;
export const BACKEND_GRAPHQL_URL = import.meta.env.VITE_BACKEND_URL;
export const BACKEND_CLOUD_RUN_URL = import.meta.env.VITE_BACKEND_URL;

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBaA0RZv-xrZ4Aiwk2v6eiVkWE6VNbLXhw",
  appId: "1:359950159463:web:3acfa08e49fa2900fc8ec2",
  authDomain: "mercateam-staging.firebaseapp.com",
  databaseURL: "https://mercateam-staging.firebaseio.com",
  measurementId: "G-89C7LCHBQR",
  messagingSenderId: "359950159463",
  projectId: "mercateam-staging",
  storageBucket: "mercateam-staging.appspot.com",
};

export const config: ServeConfig = {
  logLevel: "silent",
  mode: "staging",
  useLocalAuthentification: false,
  useLocalBackend: false,
  useLocalFirestore: false,
  useLocalFunctions: false,
};

export const DATADOG: RumInitConfiguration = {
  ...datadogDefaultConfig,
  allowedTracingUrls: ["https://api-staging.merca.team"],
  env: config.mode,
  sessionReplaySampleRate: 20,
  sessionSampleRate: 20,
  version,
};
