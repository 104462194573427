import { datadogRum } from "@datadog/browser-rum";
import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseError } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  type User,
  type UserCredential,
} from "firebase/auth";
import { gql } from "graphql-tag";
import { type Dictionary, map } from "lodash";
import { defineStore } from "pinia";

import { config } from "@/config";
import { signInWithProvider } from "@/firebase/sso";
import i18n from "@/i18n";
import { ERole, type Permission } from "@/modules/rights/types";
import { buildUserRightsInputFromPricing } from "@/modules/rights/utils/pricing";
import { setRights } from "@/modules/rights/utils/rights";
import router from "@/router";
import { logAction } from "@/tscript/analytics";
import { firebaseErrorHandler } from "@/tscript/errorHandler/firebase.error-handler";
import type { IClient, ISite, IUser } from "@/tscript/interfaces";
import type { Languages, TeamID, UserID } from "@/tscript/mercateam";
import { createDictionaryKeyNested } from "@/tscript/utils/dictionary";
import { snackbarHelper } from "@/tscript/utils/snackbar";
import { apolloProvider } from "@/vue-apollo";

import { _appDataReset } from "./_loader";
import { useSiteGlobalStore } from "./collections";
import { useGlobalStore } from "./global";

export const getUser = async (id: UserID) => {
  const { data } = await apolloProvider.defaultClient.query({
    fetchPolicy: "no-cache",
    query: gql`
      query User(
        $where: UserWhereUniqueInput!
        $orderBy: [UserRightsPricingArgsOrderByWithRelationAndSearchRelevanceInput!]
      ) {
        User(where: $where) {
          id
          is_rights_admin
          user_rights
          rights {
            id
            createdAt
            updatedAt
            status
            client_id
            site_id
            name
            default
            is_template
            users {
              id
            }
            link_sections {
              id
              status
              userRights_id
              values {
                id
                status
                readonly
                edit
              }
              section {
                id
                status
                section_name
                section_translate_name
                section_order
                default_value_readonly
                default_value_edit
                available_options_readonly
                available_options_edit
                is_complementary
                sites {
                  id
                }
              }
            }
          }
          teams_as_leader {
            id
            team_name
          }
          limited_access_teams {
            id
            team_name
          }
          client_id
          client {
            enable_planning
            has_planning
            id
            logo_url
            name
            slug
          }
          signature
          site_id
          site {
            id
            name
            rights {
              id
              createdAt
              updatedAt
              status
              client_id
              site_id
              name
              default
              is_template
              users {
                id
              }
              link_sections {
                id
                status
                userRights_id
                values {
                  id
                  status
                  readonly
                  edit
                }
                section {
                  id
                  status
                  section_name
                  section_translate_name
                  section_order
                  default_value_readonly
                  default_value_edit
                  available_options_readonly
                  available_options_edit
                }
              }
            }
            rights_admins {
              id
            }
            settings_kpis
            settings_planning_compact
            settings_versatility_user_calendar
            settings_planning_archived_employees
            settings_lastname_first
            client_id
            settings_already_assigned_score
            settings_skills_clearances_score
            team_id_for_new_employee
            settings_absent_score
            settings_shift_score
            settings_skills_end_date
            settings_training_score
            settings_training_time
            settings_hide_download_content
            settings_workload
            settings_planning_beta
            settings_monitoring_main_goal
            settings_monitoring_v1
            timezone
            status
            settings_show_score
            settings_enable_imports
            settings_restriction_score
            settings_versatilty_feature
            settings_only_trainer
            settings_only_evaluator
            external_id
            settings_without_shift
            settings_hours_worked
            settings_assignation_per_day
            hours_worked
            rest_time
            assignation_per_day
            settings_content_feature
            settings_content_feature_v2
            settings_performance_reviews_v2
            settings_performance_reviews_v1
            settings_workload_management
            settings_planning_delta
            settings_new_training_drawer
            settings_position_conflict
            pricing {
              id
              createdAt
              updatedAt
              name
              status
              sections(orderBy: $orderBy) {
                id
                section_name
                section_translate_name
                default_value_readonly
                default_value_edit
                available_options_readonly
                available_options_edit
                is_complementary
                sites {
                  id
                }
              }
            }
            complementaryRightsSections {
              id
              status
              section_name
              section_translate_name
              section_order
              default_value_readonly
              default_value_edit
              available_options_readonly
              available_options_edit
            }
            settings_content_vizualisation_signature
            settings_expertise_versatility_score
            settings_contract_score
            settings_force_approbation
            settings {
              goals {
                percentageMode
              }
            }
            settings_new_overwork
          }
          sites {
            id
            name
            rights {
              id
              name
              default
              is_template
              users {
                id
                fullname
              }
            }
            settings_planning_compact
            settings_versatility_user_calendar
            settings_planning_archived_employees
            settings_lastname_first
            client_id
            settings_already_assigned_score
            settings_skills_clearances_score
            team_id_for_new_employee
            settings_absent_score
            settings_shift_score
            settings_skills_end_date
            settings_training_score
            settings_training_time
            settings_hide_download_content
            settings_workload
            settings_planning_beta
            settings_monitoring_main_goal
            settings_monitoring_v1
            timezone
            status
            settings_show_score
            settings_enable_imports
            settings_restriction_score
            settings_versatilty_feature
            settings_only_trainer
            settings_only_evaluator
            settings_content_feature
            settings_content_feature_v2
            settings_performance_reviews_v2
            settings_performance_reviews_v1
            settings_workload_management
            settings_planning_delta
            settings_new_training_drawer
            settings_position_conflict
            external_id
            settings_without_shift
            settings_hours_worked
            settings_assignation_per_day
            hours_worked
            rest_time
            assignation_per_day
            settings_expertise_versatility_score
            settings_contract_score
            settings_force_approbation
          }
          first_name
          last_name
          fullname
          avatar_url
          category_job
          language
          role
          job
          email
          enable_birthday_notifications
          enable_expiring_training_notifications
          enable_expiring_clearance_notifications
          enable_expiring_skills_notifications
          enable_validation_training
          enable_team_change
          planning_parameter
          firstname_parameter
          enable_fidelity_notifications
          enable_expiring_contract_notifications
          enable_content_approbation
          enable_content_consultation
          enable_questionnaire_approbation
          favorite_teams
        }
      }
    `,
    variables: {
      orderBy: [
        {
          section_name: "asc",
        },
      ],
      where: {
        id,
      },
    },
  });
  return data.User;
};

export const useAuthentificationStore = defineStore("useAuthentification", {
  actions: {
    async connect(user: null | User) {
      if (user?.uid) {
        await this.setUserAuthenticated(user.uid);
        logEvent(getAnalytics(), "login_success");
        await router.push({ name: "home" });
        logAction("login");
      } else {
        snackbarHelper().error("user and/or user.uid are missing");
      }
    },
    async login(payload: { email: string; password: string }) {
      try {
        let response: null | UserCredential = null;
        const auth = getAuth();
        if (config.useLocalAuthentification) {
          if (!config.portLocalAuthentification)
            throw new Error(
              "portLocalAuthentification need to be defined if you are using useLocalAuthentification. See @/config/index.ts",
            );
          connectAuthEmulator(
            auth,
            `http://localhost:${config.portLocalAuthentification}`,
          );
          response = await signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password,
          );
        } else {
          const ipCheckResponse = await useGlobalStore().apiClient.ipCheck(
            payload.email,
          );
          if (ipCheckResponse.response !== "SUCCESS") {
            snackbarHelper().error("Forbidden access");
            return;
          }
          response = await signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password,
          );
        }
        if (response?.user?.uid) {
          await this.connect(response.user);
        }
      } catch (error) {
        console.error(error);
        if (error instanceof FirebaseError) {
          snackbarHelper().error(firebaseErrorHandler(error.code));
        } else {
          snackbarHelper().error(
            i18n.t("errors.multiple_queries_failed_message"),
          );
        }
      }
    },
    async loginSSO(email: string): Promise<null | User> {
      let response: null | UserCredential = null;
      try {
        response = await signInWithProvider(email);
        if (!response) {
          snackbarHelper().error("SSO Error");
          return null;
        }
        await this.connect(response.user);
        return response.user;
      } catch (error) {
        datadogRum.addError(error, {
          feature: "sso",
          response,
        });
        logEvent(getAnalytics(), "login_error");
        if (error instanceof Error) {
          snackbarHelper().error(error.message);
        }
        return null;
      }
    },
    async logout() {
      try {
        await signOut(getAuth());
        this.reset();
        snackbarHelper().success("Log out successful");
      } catch (error) {
        console.error(error);
        if (error instanceof FirebaseError) {
          snackbarHelper().error(error.message);
        }
      }
    },
    reset() {
      _appDataReset();
      this.data = null;
      this.active_site = null;
      this.active_site_full = null;
      this.active_client = null;
    },
    async setClientAuthenticated(userId: UserID) {
      try {
        const data = await getUser(userId);

        if (!data) throw new Error("user is not defined.");
        if (!data.client) throw new Error("client is not defined.");
        this.active_client = data.client;
      } catch (error) {
        console.error(error);
      }
    },
    async setSiteAuthenticated(userId: UserID) {
      try {
        const data = await getUser(userId);
        if (!data) throw new Error("user is not defined.");
        if (!data.site) throw new Error("site is not defined.");
        this.active_site_full = data.site;
        this.active_site = data.site.id;
      } catch (error) {
        console.error(error);
      }
    },
    async setUserAuthenticated(userId: UserID) {
      const data = await getUser(userId);
      if (!data)
        throw new Error(
          "Account restricted or deleted. Please contact your administrator.",
        );
      this.data = data;
      if (!this.data) return;
      const defaultUserRights = setRights(data.id, data.site);

      this.data.current_right = defaultUserRights;
      this.active_client = data.client;
      this.active_site_full = data.site;
      this.active_site = data.site.id;

      const complementaryRightsSections =
        this.data?.site?.complementaryRightsSections ?? [];
      const pricingSections = [
        ...(this.data?.site?.pricing.sections ?? []),
        ...complementaryRightsSections,
      ];

      const userLSections = this.data?.current_right?.link_sections;
      const permissions = buildUserRightsInputFromPricing(
        pricingSections,
        userLSections,
      );

      this.permissions = createDictionaryKeyNested(
        permissions,
        "section",
        "section_name",
      );
    },
  },
  getters: {
    IS_NOT_USER(): boolean {
      if (!this.data) return false;
      return (
        this.data.role === ERole.Enum.ADMIN ||
        this.data.role === ERole.Enum.CUSTOMER_SUCCESS
      );
    },
    limitedAccessTeamIds(): string[] {
      return map(
        this.data?.limited_access_teams as { id: string; team_name: string }[],
        (team) => team.id,
      );
    },
    sites(): ISite[] {
      const sites = this.data?.sites?.filter(
        (site) => site.status === "active",
      );
      if (sites && sites.length > 0) return sites;
      if (this.active_site_full) return [this.active_site_full];
      return [];
    },
    sitesClient(): ISite[] {
      return useSiteGlobalStore().documents ?? [];
    },
    teamIdsAsTeamLeader(): string[] {
      return map(
        this.data?.teams_as_leader as { id: string; team_name: string }[],
        (team_as_leader) => team_as_leader.id,
      );
    },
    userLanguage(state): Languages {
      return state.data?.language ?? "en";
    },
  },
  state: () => ({
    active_client: null as IClient | null,
    active_site: null as null | string,
    active_site_full: null as ISite | null,
    active_user_rights: null,
    data: null as IUser | null,
    edit_rights_scoped_to_teams: null as Dictionary<
      undefined | { id: TeamID; team_name: string }
    > | null,
    permissions: {} as Dictionary<Permission | undefined>,
  }),
});
